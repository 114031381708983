import { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import {
    ChangedProperty,
    ImageUploadResponse,
    ImageUpload,
    keyValue,
} from '../../../redux/types/types';
import { UploadArea } from '../../uploadArea/UploadArea';
import {
    getDragClass,
    getDragContainerClass,
    getItemStyle,
    getOptions,
    getOptionsLabel,
} from '../common';
import { InformationIcon } from '../../shared/InformationIcon';
import { postBookImage, removeCoverImage } from '../../../services/editBookService';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { t } from '../../../services/translationService';
import NotificationClass from '../../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../../typescript/enums/enums';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';

interface Props {
    errors: any;
    register: any;
    control: any;
    getValues: any;
    setValue: any;
    trigger: any;
    blockRef: any;
    pageVariant: string;
    lockedFields: keyValue[];
    changedProps: ChangedProperty[];
    showUploadControl: boolean;
}

export const TextImageAndLinks = ({
    register,
    control,
    getValues,
    setValue,
    blockRef,
    pageVariant,
    showUploadControl,
}: Props) => {
    const [uploadResponse, setUploadResponse] = useState<ImageUploadResponse>();
    const [deletImageResponseUrl, setDeletImageResponseUrl] = useState<string>();
    const [type, setType] = useState<string>('');
    const [typeValue, setTypeValue] = useState<string>('');
    const [utmarkelse, setUtmarkelse] = useState<any>({});
    const linkTypes = window.bokinfo.dictionaries.linkTypes;
    const language = useAppSelector((state: any) => state.language.value);
    const dispatch = useAppDispatch();

    const {
        fields: lankar,
        append: appendLank,
        move: moveLank,
        remove: removeLank,
    } = useFieldArray({
        control,
        name: 'lankar',
    });

    const {
        fields: utmarkelser,
        append: appendUtmarkelse,
        move: moveUtmarkelse,
        remove: removeUtmarkelse,
    } = useFieldArray({
        control,
        name: 'utmarkelser',
    });

    const addTypeValue = () => {
        if (typeValue !== '') {
            let newObject = {
                typ: type,
                url: typeValue,
            };
            appendLank(newObject);
            setType('');
            setTypeValue('');
        }
    };

    const addUtmarkelse = () => {
        if (utmarkelse !== '') {
            appendUtmarkelse(utmarkelse);
            setUtmarkelse('');
        }
    };

    const updateType = (event: ChangeEvent<HTMLInputElement>): void => {
        let value: string = (event.target as HTMLInputElement).value;
        setType(value);
    };

    const updateTypeValue = (event: ChangeEvent<HTMLInputElement>): void => {
        let value: string = (event.target as HTMLInputElement).value;
        setTypeValue(value);
    };

    const onTypeValueDragEnd = (result: any) => {
        moveLank(result.source.index, result.destination.index);
    };

    const onUtmarkelseDragEnd = (result: any) => {
        moveUtmarkelse(result.source.index, result.destination.index);
    };

    const updateUtmarkelse = (event: ChangeEvent<HTMLInputElement>): void => {

        let value: string = (event.target as HTMLInputElement).value;
        setUtmarkelse(value);
    };

    const removeImage = (publisherBrId: string, catalogEntryCode: string) => {
        dispatch(setLoader(true));
        removeCoverImage(language, { publisherBrId, catalogEntryCode }).then(resultUrl => {
            setDeletImageResponseUrl(resultUrl);
            dispatch(setLoader(false));
            NotificationClass.createNotification({
                type: NotificationTypes.Success,
                message: t("bildenTogsBort"),
            });
        }).catch(_ => {
            dispatch(setLoader(false));
            NotificationClass.createNotification({
                type: NotificationTypes.Error,
                message: t("bildenTogsInteBort"),
            });
        });
    }

    const imageCallback = (base64ImageString: string, fileName: string) => {
        if (base64ImageString && getValues('artikelnummer')) {
            base64ImageString = base64ImageString.replace(
                /^data:image\/(png|jpg|jpeg|gif|bmp);base64,/,
                ''
            );

            const input = {
                data: base64ImageString,
                filnamn: fileName,
                forlagId: getValues('forlagId'),
            } as ImageUpload;
            dispatch(setLoader(true));
            postBookImage(language, getValues('artikelnummer'), input)
                .then((responseData: any) => {
                    NotificationClass.createNotification({
                        type: NotificationTypes.Success,
                        message: t('bildenHarLaddat'),
                    });
                    setUploadResponse(responseData);
                    dispatch(setLoader(false));
                })
                .catch((error: any) => {
                    dispatch(setLoader(false));
                    NotificationClass.createNotification({
                        type: NotificationTypes.Error,
                        message: error as string,
                    });
                });
        }
    };

    useEffect(() => {
        if (deletImageResponseUrl) {
            setValue('omslagsbildUrl', deletImageResponseUrl);

            let link = lankar.findIndex((l: any) => l.typ === 'omslagsbild');

            if (link >= 0) {
                removeLank(link);
            }
        }
    }, [deletImageResponseUrl])

    useEffect(() => {
        if (uploadResponse) {
            setValue('omslagsbildUrl', uploadResponse?.omslagsbildUrl);

            const newObject = {
                typ: uploadResponse?.link.type,
                url: uploadResponse?.link.url,
            };
            appendLank(newObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadResponse]);

    return (
        <>
            <h3 ref={blockRef}>{t('textBildOchLankar')}</h3>
            <Col xs={12}>
                <hr className="u-text-grey" />
            </Col>
            <Card className="nopadding my-4">
                <Card.Body className="p-3">
                    <Row>
                        <Col xs={7}>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 text-body">
                                    {t('katalogtext')}
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder={t('skrivEllips')}
                                    className="u-text-grey"
                                    {...register('katalogtext')}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={7}>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1 text-body">{t('saga')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder={t('skrivEllips')}
                                    className="u-text-grey"
                                    {...register('saga')}
                                />
                            </Form.Group>
                        </Col>
                        {pageVariant !== 'quick' && (
                            <>
                                <Col className="row" xs={12}>
                                    {showUploadControl && (
                                        <>
                                            <Form.Label className="mb-1 text-body">
                                                {t('aktuellOmslagsbild')}
                                            </Form.Label>
                                            {(lankar as []).some((l: { typ: string; }) => l.typ === 'omslagsbild') ? (
                                                <>
                                                    <div className="d-flex align-items-top col-11">
                                                        <img
                                                            className="mb-3 d-block w-25"
                                                            src={getValues('omslagsbildUrl')}
                                                            alt="omslagsbild"
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-top col-1">
                                                        <i
                                                            className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                                            onClick={() => removeImage(getValues('forlagId'), getValues('artikelnummer'))}
                                                        />
                                                    </div>
                                                </>

                                            ) : (
                                                <UploadArea
                                                    callback={imageCallback}
                                                    control={control}
                                                    setValue={setValue}
                                                    propName={'omslagsbild'}
                                                />
                                            )}
                                        </>
                                    )}
                                </Col>
                                <Col xs={12}>
                                    <hr className="u-text-grey" />
                                </Col>
                                <Col xs={7}>
                                    <Form.Group as={Col} className="mb-3 col me-3">
                                        <Form.Label className="mb-1 text-body">
                                            {t('lankTyp')}
                                        </Form.Label>
                                        <Form.Control
                                            as={'select'}
                                            type="select"
                                            className="form-select"
                                            placeholder={t('valjEllips')}
                                            value={type}
                                            onChange={updateType}
                                        >
                                            {showUploadControl ? getOptions('linkTypes').filter((option: any) => option.key !== 'omslagsbild') : getOptions('linkTypes')}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <div className="d-flex align-items-end w-100">
                                        <Form.Group as={Col} className="mb-3 col me-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('lankVarde')}
                                                <InformationIcon
                                                    text={t('hjalpLankvärde')}
                                                    inline={true}
                                                    className="ps-1"
                                                />
                                            </Form.Label>
                                            <Form.Control
                                                placeholder={t('skrivEllips')}
                                                value={typeValue}
                                                onChange={updateTypeValue}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3 col-auto">
                                            <Button
                                                className="primary"
                                                type="button"
                                                onClick={addTypeValue}
                                            >
                                                <i className="bi bi-plus"></i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <DragDropContext onDragEnd={onTypeValueDragEnd}>
                                    <Droppable droppableId="droppableLaromedel">
                                        {(provided: any, snapshot: any) => (
                                            <Col
                                                xs={12}
                                                className={`mb-3 ${getDragContainerClass(
                                                    snapshot.isDraggingOver
                                                )}`}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {lankar.map((row: any, index: number) => {
                                                    return (
                                                        <Draggable
                                                            key={row.id}
                                                            draggableId={row.id}
                                                            index={index}
                                                        >
                                                            {(provided: any, snapshot: any) => (
                                                                <Row
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    key={row.id}
                                                                    className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                                                        } mt-2 p-1 ${getDragClass(
                                                                            snapshot.isDragging
                                                                        )}`}
                                                                >
                                                                    <Col
                                                                        xs={2}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        {linkTypes.filter((l: any) => l.key === row.typ)[0]?.value}
                                                                    </Col>
                                                                    <Col
                                                                        xs={9}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        {getValues(`lankar.${index}.url`)}
                                                                    </Col>
                                                                    <Col
                                                                        xs={1}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        {(row.typ !== "omslagsbild") && <i
                                                                            className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                                                            onClick={() => removeLank(index)}
                                                                        />}
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </Col>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Col xs={12}>
                                    <hr className="u-text-grey" />
                                </Col>
                                <Col xs={12}>
                                    <Form.Group as={Col} className="mb-3 col me-3">
                                        <Form.Check
                                            label={t('forhandsinfo')}
                                            {...register('forhandsinfo')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <div className="d-flex align-items-end w-100">
                                        <Form.Group as={Col} className="mb-3 col me-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('utmarkelser')}
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                value={utmarkelse}
                                                onChange={updateUtmarkelse}
                                            >
                                                {getOptions('awards')}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3 col-auto">
                                            <Button
                                                className="primary"
                                                type="button"
                                                onClick={addUtmarkelse}
                                            >
                                                <i className="bi bi-plus"></i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <DragDropContext onDragEnd={onUtmarkelseDragEnd}>
                                    <Droppable droppableId="droppablegymnasieprogram">
                                        {(provided: any, snapshot: any) => (
                                            <Col
                                                xs={12}
                                                className={`mb-3 ${getDragContainerClass(
                                                    snapshot.isDraggingOver
                                                )}`}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {utmarkelser.map((row: any, index: number) => {
                                                    return (
                                                        <Draggable
                                                            key={row.id}
                                                            draggableId={row.id}
                                                            index={index}
                                                        >
                                                            {(provided: any, snapshot: any) => (
                                                                <Row
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    key={row.id}
                                                                    className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                                                        } mt-2 p-1 ${getDragClass(
                                                                            snapshot.isDragging
                                                                        )}`}
                                                                >
                                                                    <Col
                                                                        xs={11}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        {getOptionsLabel(
                                                                            'awards',
                                                                            getValues(`utmarkelser.${index}`)
                                                                        )}
                                                                    </Col>
                                                                    <Col
                                                                        xs={1}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        <i
                                                                            className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                                                            onClick={() => removeUtmarkelse(index)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </Col>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Col xs={12}>
                                    <hr className="u-text-grey" />
                                </Col>
                                <Col xs={7}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-1 text-body">
                                            {t('kommentar')}
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            placeholder={t('skrivEllips')}
                                            {...register('kommentar')}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
