import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Coworker } from './blocks/Coworker';
import { Format } from './blocks/Format';
import { Language } from './blocks/Language';
import { PublishingHouse } from './blocks/PublishingHouse';
import { SubjectClassification } from './blocks/SubjectClassification';
import { Title } from './blocks/Title';
import { ChangedProperty } from '../../redux/types/types';
import { Learning } from './blocks/Learning';
import { TextImageAndLinks } from './blocks/TextImageAndLinks';
import { postBook } from '../../services/editBookService';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { t } from '../../services/translationService';
import { Comments } from './blocks/Comments';
import { scrollToFirstError } from './common';
import { Eudr } from './blocks/Eudr';

export const Quick = (
	{
		blockRefs,
		setListOfErrors,
		onSubmitError,
		validationRef,
		reviewed,
	}: any) => {
	if (window.bokinfo.book?.serie && !Array.isArray(window.bokinfo.book.serie)) {
		window.bokinfo.book.serie = [window.bokinfo.book.serie];
	}
	const [data, setData] = useState<any>(window.bokinfo.book || {});
	const [lockedFields, setLockedFields] = useState<any>(
		window.bokinfo.lockedProperties
	);
	const [changedProps, setChangedProps] = useState<ChangedProperty[]>(
		window.bokinfo.updatedFields || []
	);
	const [showUploadControl, setShowUploadControl] = useState<boolean>(
		window.bokinfo.showUploadControl || false
	);
	const [submitData, setSubmitData] = useState<any>({});
	const [coworkerErrors, setCoworkerErrors] = useState<number[]>([]);
	const language = useAppSelector((state: any) => state.language.value);
	const dispatch = useAppDispatch();

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		register,
		control,
		getValues,
		setValue,
		trigger,
		watch,
	}: any = useForm({
		mode: 'onSubmit',
		defaultValues: data,
	});

	  const setFormValues = (values: any) => {
		setData((prevValues: any) => ({
			...prevValues,
			...values,
		}));
        !values.serie ? (values.serie = '') : (values.serie = values.serie[0]);
        !values.tryckort ? (values.tryckort = '') : (values.tryckort = values.tryckort[0]);
		setSubmitData((prevValues: any) => ({
			...prevValues,
			...values,
		}));
	};

    const checkValidationErrors = (errorMessages: any) => {
		let coworkerErrorRows: number[] = [];
		const coworkerReg = new RegExp('\\d_Contributor');
		errorMessages?.map((entry: any) => {
			const test = coworkerReg.exec(entry.key);
			if (test && test?.length > 0) {
				const value = Number(test[0].substring(0, 1));
				!coworkerErrorRows.includes(value) && coworkerErrorRows.push(value);
			}
		});

		setCoworkerErrors(coworkerErrorRows);
	};

	const clearCoworkerError = (errorRowNumber: number) => {
		setCoworkerErrors(
			coworkerErrors.filter((error: number) => error !== errorRowNumber)
		);
    };

    useEffect(() => {
        setValue("granskad", reviewed);
    }, [reviewed])

    useEffect(() => {
        if (isSubmitting) {
            scrollToFirstError();
        }
    }, [isSubmitting])

	useEffect(() => {
		//Here we should save data
		if (submitData?.artikelnummer) {
            dispatch(setLoader(true));
			postBook(language, submitData)
                .then((responseData: any) => {
					setListOfErrors([]);
					NotificationClass.createNotification({
						type: NotificationTypes.Success,
						message: t('valideringSparaBokOk'),
					});
					dispatch(setLoader(false));
				})
				.catch((error: any) => {
					setListOfErrors(error.messages);
					checkValidationErrors(error.messages);
					dispatch(setLoader(false));
					if (validationRef && validationRef.current) {
						validationRef.current.scrollIntoView();
					}
				});
		}
	}, [submitData]);

	const onSubmit = (values: any, event: Event) => {
		setFormValues(values);
	};

	return (
		<>
			<Form id="quick" className="pt-3" onSubmit={handleSubmit(onSubmit, onSubmitError)}>
				<Title
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					blockRef={(el: any) => (blockRefs.current[0] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
                    changedProps={changedProps}
                    trigger={trigger}
				/>
				<Format
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    blockRef={(el: any) => (blockRefs.current[1] = el)}
                    pageVariant="quick"
                    lockedFields={lockedFields}
                    changedProps={changedProps}
                    watch={watch}
                    trigger={trigger}
                    control={control}
				/>
				<SubjectClassification
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					blockRef={(el: any) => (blockRefs.current[2] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
				/>
				<Coworker
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					trigger={trigger}
					blockRef={(el: any) => (blockRefs.current[3] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
					errorRows={coworkerErrors}
					clearCoworkerError={clearCoworkerError}
				/>
				<Language
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					trigger={trigger}
					blockRef={(el: any) => (blockRefs.current[4] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
				/>
				<PublishingHouse
					errors={errors}
					register={register}
					blockRef={(el: any) => (blockRefs.current[5] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
				/>
				<Learning
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					blockRef={(el: any) => (blockRefs.current[6] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
				/>
				<TextImageAndLinks
					errors={errors}
					register={register}
					control={control}
					getValues={getValues}
					setValue={setValue}
					trigger={trigger}
					blockRef={(el: any) => (blockRefs.current[7] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
					showUploadControl={showUploadControl}
				/>
				<Comments
					errors={errors}
					register={register}
					blockRef={(el: any) => (blockRefs.current[8] = el)}
					pageVariant="quick"
					lockedFields={lockedFields}
					changedProps={changedProps}
                />
                <Eudr
                    errors={errors}
                    register={register}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    blockRef={(el: any) => (blockRefs.current[10] = el)}
                    pageVariant="quick"
                />
			</Form>
		</>
	);
};
