interface INestedErrorObjectRef {
	name: string;
}
interface INestedErrorObject {
	type?: string;
	message?: string;
	ref?: INestedErrorObjectRef;
	marker?: string
	readonly value?: string;
}

export class NestedErrorObject implements INestedErrorObject {
	type?: string;
	message?: string;
	ref?: INestedErrorObjectRef;
	value?: string;

	constructor(data?: any) {
		this.type = data.type;
		this.message = data.message;
		this.ref = data.ref;
		this.value = this.message || this.ref?.name ? `${this.message} (${this.ref?.name})` : undefined;
	}
}

/**
 * Recursively traverses a nested object structure to collect all objects that contain a 'message' property.
 * Each object with a 'message' property is wrapped in a NestedErrorObject and added to the result array.
 * 
 * @param obj - The input object to search through for nested error objects.
 * @param result - An array to accumulate the found NestedErrorObject instances. Defaults to an empty array.
 * @returns An array of NestedErrorObject instances containing all found errors with a 'message' property.
 */
export const collectNestedErrors = (obj: any, result: NestedErrorObject[] = []): NestedErrorObject[] => {
	for (let key in obj) {
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			if ('message' in obj[key]) {
				result.push(new NestedErrorObject(obj[key]));
			}
			collectNestedErrors(obj[key], result);
		}
	}
	return result;
}