import { ChangeEvent, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { t } from '../../../services/translationService';
import { getDragClass, getDragContainerClass, getItemStyle, getOptions, getOptionsLabel, shouldBeLocked } from '../common';

interface Props {
	errors: any;
	register: any;
	control: any;
	getValues: any;
	setValue: any;
	trigger: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
	errorRows?: number[];
	clearCoworkerError: any;
}

export const Coworker = ({
	errors,
	register,
	control,
    getValues,
    setValue,
	trigger,
	blockRef,
	pageVariant,
	lockedFields,
	errorRows,
	clearCoworkerError,
}: Props) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [currentIndex, setCurrentIndex] = useState<number | undefined>(
		undefined
    );

	const {
		fields: medarbetare,
		append: appendMedarbetare,
		remove: removeMedarbetare,
		update: updateMedarbetare,
		move: moveMedarbetare,
	} = useFieldArray({
        control,
		name: 'medarbetare',
	});

	const onMoveMedarbetare = (result: any) => {
		moveMedarbetare(result.source.index, result.destination.index);
    };

    const onUpdateMedarbetare = (event: ChangeEvent<HTMLInputElement>): void =>
    {
        const index = currentIndex !== undefined ? currentIndex : medarbetare.length - 1;
        const changedItem = getValues(`medarbetare.${index}`);

        if (changedItem.namnlosTyp !== "" && changedItem.namnlosTyp !== "0") {
            setValue(`medarbetare.${index}.fornamn`, undefined);
            setValue(`medarbetare.${index}.efternamn`, undefined);
            setValue(`medarbetare.${index}.fodelsear`, undefined);
            setValue(`medarbetare.${index}.isni`, undefined);
            setValue(`medarbetare.${index}.url`, undefined);
        }

        trigger(`medarbetare.${index}`);
    }

    const saveCoworker = () =>
		trigger(
			`medarbetare.${
				currentIndex !== undefined ? currentIndex : medarbetare.length - 1
			}`
        ).then((validResult: boolean) => {
			if (validResult) {
                const changed = getValues(currentIndex === undefined ? `medarbetare.${medarbetare.length - 1}` : `medarbetare.${currentIndex}`);

                // Remove name if unnamed type
                if (changed.namnlosTyp !== "" && changed.namnlosTyp !== "0") {
                    changed.fornamn = undefined;
                    changed.efternamn = undefined;
                    changed.fodelsear = undefined;
                    changed.insi = undefined;
                    changed.url = undefined;
                }

				currentIndex === undefined && removeMedarbetare(medarbetare.length - 1);
				currentIndex !== undefined
					? updateMedarbetare(
							currentIndex,
							changed
					  )
					: appendMedarbetare({
							...changed,
							fodelsear: changed.fodelsear,
					  });
				currentIndex && clearCoworkerError(currentIndex);
				setShowModal(false);
			}
		});

	const displayModal = (index?: number) => {
		index === undefined ? appendMedarbetare({}) : setCurrentIndex(index);
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
		currentIndex === undefined
			? removeMedarbetare(medarbetare.length - 1)
			: clearCoworkerError(currentIndex);
    };

	return (
		<>
			<h3 ref={blockRef}>{t('medarbetare')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="my-4 nopadding">
				<Card.Body className="">
					{medarbetare?.length > 0 ? (
						<div className='mb-3 w-100 table'>
							<div>
								<div className="small px-3 py-3 d-flex">
									<div style={{width: "10%"}}>
										<span className="d-block">{t('typ')}</span>
									</div>
									<div style={{width: "20%"}}>
										<span className="d-block">{t('namn')}</span>
									</div>
									<div style={{width: "15%"}}>
										<span className="d-block">{t('fodelsear')}</span>
									</div>
									<div style={{width: "30%"}}>
										<span className="d-block">{t('isni')}</span>
									</div>
									<div style={{width: "15%"}}>
										<span className="d-block">{t('url')}</span>
									</div>
									<div style={{width: "10%"}}>
										<span className="d-block"></span>
									</div>
								</div>
							</div>
							<DragDropContext onDragEnd={onMoveMedarbetare}>
								<Droppable droppableId="droppableMedarbetare">
									{(provided: any, snapshot: any) => (
										<div
											className={`${getDragContainerClass(
												snapshot.isDraggingOver
											)}`}
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
												
                                            {medarbetare.map((row: any, index: number) => {
												return (
													<Draggable
														key={row.id}
														draggableId={row.id}
														index={index}
														isDragDisabled={shouldBeLocked(
															lockedFields,
															'medarbetare'
														)}
													>
														{(provided: any, snapshot: any) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																key={row.id}
																className={`${
																	index % 2 === 1 ? 'u-background-grey' : ''
																} d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center ${getDragClass(
																	snapshot.isDragging
																)}`}
															>
																<div style={{width: "10%"}}>
																	{getOptionsLabel('contributorTypes', getValues(`medarbetare.${index}.typ`))}
																</div>
																<div style={{width: "20%"}}>
                                                                    {
                                                                        ((getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0") && getOptionsLabel("unnamedContributorTypes", getValues(`medarbetare.${index}.namnlosTyp`))) ||
                                                                        ((getValues(`medarbetare.${index}.fornamn`) + ' ' + getValues(`medarbetare.${index}.efternamn`)))
                                                                    }
																</div>
																<div style={{width: "15%"}}>
																	{getValues(`medarbetare.${index}.fodelsear`)}
																</div>
																<div style={{width: "30%"}}>
																	<span className="text-truncate d-block">
																		{getValues(`medarbetare.${index}.isni`)}
																	</span>
																</div>
																<div style={{width: "15%"}}>
																	<span className="text-truncate d-block">
																		{getValues(`medarbetare.${index}.url`)}
																	</span>
																</div>
																<div style={{width: "10%"}}>
																	{!shouldBeLocked(
																		lockedFields,
																		'medarbetare'
																	) && (
																		<div className="d-flex gap-3 justify-content-end">
																			<i
																				className="bi bi-pencil u-text-primary u-cursor-pointer"
																				onClick={() => displayModal(index)}
																			/>
																			<i
																				className="bi bi-trash3 u-text-error u-cursor-pointer"
																				onClick={() => removeMedarbetare(index)}
																			/>
																		</div>
																	)}																
																</div>
															</div>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					) : (
						medarbetare.length === 0 && (
							<p className="text-muted p-3">Inga medarbetare tillagda</p>
						)
					)}
				</Card.Body>
				{!shouldBeLocked(lockedFields, 'medarbetare') && (
					<Card.Footer>
						<Button
							type="button"
							className="primary"
							onClick={() => displayModal()}
						>
							<i className="bi bi-plus" />
							{t('laggTillMedarbetare')}
						</Button>
					</Card.Footer>
				)}
			</Card>

			<Modal
				show={showModal}
				centered
				size="lg"
				onHide={closeModal}
				onExited={() => setCurrentIndex(undefined)}
			>
				<Modal.Header>
					<p>{t('laggTillMedarbetare')}</p>
				</Modal.Header>
				<Modal.Body>
                    {medarbetare.map((row: any, index: number) => {
						return (
							index ===
								(currentIndex !== undefined
									? currentIndex
									: medarbetare.length - 1) && (
								<Row key={index}>
									<Col xs={12}>
										<Form.Group className="mb-3">
											<Form.Label className="mb-1 text-body">
												{t('typ')}
											</Form.Label>
											<Form.Control
												as={'select'}
												type="select"
												className="form-select"
												placeholder={t('valjEllips')}
												{...register(`medarbetare.${index}.typ`, {
													required: t('valideringTyp'),
												})}
												isInvalid={
													!!errors.medarbetare && errors.medarbetare[index].typ
												}
											>
												{getOptions('contributorTypes')}
											</Form.Control>
											{!!errors.medarbetare &&
												errors.medarbetare[index].typ && (
													<Form.Control.Feedback type="invalid">
														{`${errors.medarbetare[index].typ.message}`}
													</Form.Control.Feedback>
												)}
										</Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t("namnlosMedarbetarTyp")}
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                {...register(`medarbetare.${index}.namnlosTyp`, {
                                                    onChange: onUpdateMedarbetare,
                                                    deps: [`medarbetare.${index}.fornamn`, `medarbetare.${index}.efternamn`]
                                                })}
                                            >
                                                {getOptions('unnamedContributorTypes')}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
									<Col xs={12} md={6}>
										<Form.Group className="mb-3">
											<Form.Label className="mb-1 text-body">
												{t('fornamn')}
											</Form.Label>
											<Form.Control
												key={row.id}
												placeholder={t('skrivEllips')}
                                                {...register(`medarbetare.${index}.fornamn`, {
                                                    validate: (value: any, formValues: any) => {
                                                        if (!value && (!getValues(`medarbetare.${index}.namnlosTyp`) || getValues(`medarbetare.${index}.namnlosTyp`) === "0"))
                                                            return t('valideringFornamn')
                                                    },
                                                    disabled: getValues(`medarbetare.${index}.namnlosTyp`) && getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0"
                                                })}
												isInvalid={
													!!errors.medarbetare &&
                                                    errors.medarbetare[index].fornamn
												}
											/>
											{!!errors.medarbetare &&
												errors.medarbetare[index].fornamn && (
													<Form.Control.Feedback type="invalid">
														{`${errors.medarbetare[index].fornamn.message}`}
													</Form.Control.Feedback>
												)}
										</Form.Group>
									</Col>
									<Col xs={12} md={6}>
										<Form.Group className="mb-3">
											<Form.Label className="mb-1 text-body">
												{t('efternamn')}*
											</Form.Label>
											<Form.Control
												key={row.id}
												placeholder={t('skrivEllips')}
                                                {...register(`medarbetare.${index}.efternamn`, {
                                                    validate: (value: any, formValues: any) => {
                                                        if (!value && (!getValues(`medarbetare.${index}.namnlosTyp`) || getValues(`medarbetare.${index}.namnlosTyp`) === "0"))
                                                            return t('valideringEfternamn')
                                                    },
                                                    disabled: getValues(`medarbetare.${index}.namnlosTyp`) && getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0"
                                                })}
												isInvalid={
													!!errors.medarbetare &&
                                                    errors.medarbetare[index].efternamn
												}
											/>
											{!!errors.medarbetare &&
												errors.medarbetare[index].efternamn && (
													<Form.Control.Feedback type="invalid">
														{`${errors.medarbetare[index].efternamn.message}`}
													</Form.Control.Feedback>
												)}
										</Form.Group>
									</Col>
									<Col xs={12} md={6}>
										<Form.Group className="mb-3">
											<Form.Label className="mb-1 text-body">
												{t('fodelsearYyyy')}
											</Form.Label>
											<Form.Control
												key={row.id}
												type={'number'}
												placeholder={t('skrivEllips')}
												{...register(`medarbetare.${index}.fodelsear`, {
                                                    maxLength: 4,
                                                    disabled: getValues(`medarbetare.${index}.namnlosTyp`) && getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0"
												})}
												isInvalid={
													!!errors.medarbetare &&
													errors.medarbetare[index].fodelsear
												}
											/>
											{!!errors.medarbetare &&
												errors.medarbetare[index].fodelsear?.type ===
													'maxLength' && (
													<Form.Control.Feedback
														type="invalid"
														className="d-block"
													>
														{t('valideringDatum')}
													</Form.Control.Feedback>
												)}
										</Form.Group>
									</Col>
									<Col xs={12} md={6}>
										<Form.Group className="mb-3">
											<Form.Label className="mb-1 text-body">
												{t('isni')}
											</Form.Label>
											<Form.Control
												key={row.id}
												placeholder={t('skrivEllips')}
                                                {...register(`medarbetare.${index}.isni`, {
                                                    disabled: getValues(`medarbetare.${index}.namnlosTyp`) && getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0"
                                                })}
											/>
										</Form.Group>
									</Col>
									{pageVariant !== 'publisher' && (
										<Col xs={12}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('url')}
												</Form.Label>
												<Form.Control
													key={row.id}
													placeholder={t('skrivEllips')}
                                                    {...register(`medarbetare.${index}.url`, {
                                                        disabled: getValues(`medarbetare.${index}.namnlosTyp`) && getValues(`medarbetare.${index}.namnlosTyp`) !== "" && getValues(`medarbetare.${index}.namnlosTyp`) !== "0"
                                                    })}
												/>
											</Form.Group>
										</Col>
									)}
								</Row>
							)
						);
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						{t('avbryt')}
					</Button>
					<Button variant="primary" onClick={saveCoworker}>
						{t('spara')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
